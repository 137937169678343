import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography, Button, TextField } from "@mui/material";
import { styled } from "@mui/system";
import { FormValues, validationSchema } from "./schemas/schema";
import { useLanguageStore } from "../components/utils/LanguageStore";

const FormContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "30px",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  maxWidth: "450px",
  width: "100%",
  margin: "0 auto",
  display: "flex",
  flexDirection: "column",
  gap: "15px",
}));

const ContactForm: React.FC = () => {
  const { t } = useLanguageStore();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (values: FormValues) => {
    try {
      const response = await fetch("https://formspree.io/f/xbljkvrp", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      });

      if (!response.ok) throw new Error(t("contact.errorMessage"));

      alert(t("contact.successMessage"));
      reset();
    } catch (error) {
      console.error(error);
      alert(t("contact.errorMessage"));
    }
  };

  return (
    <FormContainer>
      <Typography
        variant="h5"
        component="h2"
        textAlign="center"
        color="#10A5D1"
        fontWeight="bold"
      >
        {t("contact.formTitle")}
      </Typography>
      <Typography variant="body1" textAlign="center" color="textSecondary">
        {t("contact.formSubtitle")}
      </Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        {/* Campo Nombre */}
        <TextField
          label={t("contact.nameLabel")}
          fullWidth
          {...register("name")}
          error={!!errors.name}
          helperText={errors.name?.message}
        />

        {/* Campo Correo Electrónico */}
        <TextField
          label={t("contact.emailLabel")}
          fullWidth
          {...register("email")}
          error={!!errors.email}
          helperText={errors.email?.message}
        />

        {/* Campo Mensaje */}
        <TextField
          label={t("contact.messageLabel")}
          fullWidth
          multiline
          rows={4}
          {...register("message")}
          error={!!errors.message}
          helperText={errors.message?.message}
          placeholder={t("contact.messagePlaceholder")}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            backgroundColor: "#10A5D1",
            color: "#fff",
            "&:hover": { backgroundColor: "#0d82a7" },
            textTransform: "none",
            fontSize: "1rem",
            padding: "10px 0",
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? t("contact.sendingButton") : t("contact.submitButton")}
        </Button>
      </form>
    </FormContainer>
  );
};

export default ContactForm;
