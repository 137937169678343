import React, { useState } from "react";
import { Container, Typography, Box, TextField } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import GridComponent from "../components/GridComponent";
import { useQuery } from "@tanstack/react-query";
import { useLanguageStore } from "../components/utils/LanguageStore";

const educationData1 = [
  {
    title: "Test Anti Phishing",
    description: "Jigsaw Game realizado por Google. | **Gratis**",
    link: "https://phishingquiz.withgoogle.com/",
    image:
      "https://imgs.search.brave.com/twfrx2MmjON9xdpAidfTMVgWaG663Or8B5E81zG-iGM/rs:fit:860:0:0:0/g:ce/aHR0cHM6Ly9pLmlu/c2lkZXIuY29tLzVj/NDc2YTZiYmRlNzBm/NGQ2MDIwNTBjMD93/aWR0aD03MDA",
  },
  {
    title: "Test contra CYBERBULLYING",
    description: "Quiz interacitvo realizado por Wordwall.net | *Gratis*",
    link: "https://wordwall.net/es/resource/3927645/ciberbullying",
    image:
      "https://wordwallscreens.azureedge.net/400/5173e56ecd7a4291adee6ff92b77b390_0",
  },
  {
    title: "Python Free 1 hour Course | Curso de 1 hora de Python",
    description: "Python desde cero en una hora para principiantes | *Gratis*",
    link: "https://www.youtube.com/watch?v=IimBRwHhW54",
    image: "https://i.ytimg.com/vi/IimBRwHhW54/maxresdefault.jpg",
  },
];

const educationData2 = [
  {
    title: "Cisco: NetAcad",
    description:
      "FREE online courses. In-person learning. Certification-aligned pathways in topics like Cybersecurity, Networking, and Python.",
    link: "https://www.netacad.com/",
    image: "https://cyber.cap.gov/media/cms/NetAcad_Logo_F1571E4CB015F.png",
  },
  {
    title: "FreeCodeCamp",
    description:
      "Explora cursos y programas para adquirir habilidades en diversos roles dentro del campo de la Seguridad de la Información. *Gratis*.",
    link: "https://www.freecodecamp.org/espanol/learn/information-security/",
    image:
      "https://storage.googleapis.com/grow-with-goog-publish-prod-media/images/New_GWG_site__800_x_421_px_1.original.format-jpeg.jpg",
  },
  {
    title: "Platzi",
    description:
      "Escuela de Ciberseguridad dictada por la Plataforma Educativa Online Platzi. | *Pago*",
    link: "https://platzi.com/escuela/ciberseguridad/",
    image:
      "https://latamlist.com/wp-content/uploads/2018/05/platzi-logo-huge.png",
  },
];

const fetchEducationalResources = async (): Promise<
  { title: string; description: string; link: string; image: string }[]
> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve([...educationData1, ...educationData2]);
    }, 500);
  });
};

const EducationScreen: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useLanguageStore();

  const {
    data: eduResource,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["educationalResources"],
    queryFn: fetchEducationalResources,
    staleTime: 1000,
    placeholderData: [],
  });

  const filteredEducationalResources = eduResource?.filter(
    (eduResource) =>
      eduResource.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      eduResource.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (isLoading) return <p>{t("education.loading")}</p>;
  if (error) return <p>{t("education.error")}</p>;

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 2,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            fontSize: {
              xs: "1.5rem",
              sm: "2rem",
              md: "2.5rem",
              lg: "3rem",
              xl: "3.5rem",
            },
            textAlign: "center",
            marginTop: { xs: 4, sm: 6, md: 8 },
          }}
        >
          {t("education.title")}
        </Typography>
        <Box
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            padding: "15px",
          }}
        >
          <TextField
            label={t("education.searchPlaceholder")}
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{
              width: "70%",
              "& .MuiOutlinedInput-root": {
                borderRadius: "25px", // Bordes redondeados
                backgroundColor: "#ffffff", // Fondo blanco
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Sombra suave
                "& fieldset": {
                  borderColor: "#cccccc", // Color del borde inicial
                },
                "&:hover fieldset": {
                  borderColor: "#10A5D1", // Color del borde al pasar el cursor
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#10A5D1", // Color del borde al hacer foco
                  boxShadow: "0 0 8px rgba(16, 165, 209, 0.5)", // Sombra adicional en foco
                },
              },
              "& .MuiInputLabel-root": {
                color: "#666666", // Color del label
                "&.Mui-focused": {
                  color: "#10A5D1", // Color del label al hacer foco
                },
              },
              "& .MuiInputBase-input": {
                padding: "12px 16px", // Ajusta el padding interno
              },
            }}
            InputProps={{
              startAdornment: (
                <Box sx={{ marginRight: "8px", color: "#10A5D1" }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: "24px", height: "24px" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21 21l-4.35-4.35M10.5 19a8.5 8.5 0 100-17 8.5 8.5 0 000 17z"
                    />
                  </svg>
                </Box>
              ),
            }}
          />
        </Box>

        <Box sx={{ width: "100%", padding: "16px 0" }}>
          <GridComponent cardData={filteredEducationalResources ?? []} />
        </Box>
        <Box sx={{ height: "50px", backgroundColor: "#f0f0f0" }} />

        {filteredEducationalResources &&
          filteredEducationalResources.length === 0 && (
            <Typography
              variant="h6"
              color="textSecondary"
              sx={{ marginTop: 2 }}
            >
              {t("education.noResults")}
            </Typography>
          )}
      </Container>
      <Box sx={{ height: "100px", backgroundColor: "#f0f0f0" }} />
      <Footer />
    </div>
  );
};

export default EducationScreen;
