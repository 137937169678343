import { z } from "zod";

export const validationSchema = z.object({
  name: z
    .string()
    .min(3, "El nombre debe tener al menos 3 caracteres")
    .max(15, "El nombre es demasiado largo"),
  email: z
    .string()
    .email("Ingresa un correo válido"),
  message: z
    .string()
    .min(10, "El mensaje debe contener al menos 10 caracteres"),
});

export type FormValues = {
  name: string;
  email: string;
  message: string;
};
