import React from "react";
import {
  Container,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import ContactForm from "../components/ContactForm";
import { useLanguageStore } from "../components/utils/LanguageStore";

const ContactScreen: React.FC = () => {
  const { t } = useLanguageStore();

  const collaborationItems = [
    t("contact.workshops"),
    t("contact.innovativeIdeas"),
    t("contact.brandCollaborations"),
    t("contact.donations"),
  ];

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box textAlign="center" py={5}>
          <Typography
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              textAlign: "center",
              fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" },
              marginTop: { xs: 4, sm: 6, md: 8 },
              marginBottom: 3,
            }}
          >
            {t("contact.title")}
          </Typography>

          <Typography variant="body1" gutterBottom sx={{ mt: 2, mb: 4 }}>
            {t("contact.description")}
            <br />
            <strong>{t("contact.callToAction")}</strong>
          </Typography>

          <List>
            {collaborationItems.map((item, index) => (
              <ListItem key={index} sx={{ justifyContent: "center" }}>
                <ListItemText primary={item} sx={{ textAlign: "center" }} />
              </ListItem>
            ))}
          </List>

          {/* Botón de colaboración */}
          <Box mt={3}>
            <CustomButton
              label={t("contact.joinButton")}
              to="https://buymeacoffee.com/concienciadigital"
              onClick={() => console.log("Button clicked!")}
            />
          </Box>
        </Box>

        {/* Formulario de contacto */}
        <Box mt={6} mb={8} sx={{ width: "100%" }}>
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textAlign: "center",
              marginBottom: "1rem",
            }}
          >
            {t("contact.formTitle")}
          </Typography>
          <ContactForm />
        </Box>
      </Container>
      <Footer />
    </div>
  );
};

export default ContactScreen;
