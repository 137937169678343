import React, { useState } from "react";
import {
  Container,
  Typography,
  Box,
  Button,
  Modal,
  Card,
  CardContent,
  CardActions,
  CardMedia,
} from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import PomodoroTimer from "../components/PomodoroTimer";
import { useLanguageStore } from "../components/utils/LanguageStore";
import { useNavigate } from "react-router-dom";
import QuoteComponent from "../components/QuoteComponent";
import PortadaTutorial from "../assets/portadatutorial.png";
const ToolsScreen: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useLanguageStore();
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
          backgroundColor: "transparent",
          minHeight: "calc(100vh - 64px)",
          display: "flex",
          flexDirection: "column",
          gap: 4,
        }}
      >
        <Typography
          variant="h2"
          gutterBottom
          sx={{
            color: "#10A5D1",
            textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            textAlign: "center",
            fontSize: { xs: "1.8rem", sm: "2.5rem", md: "3rem" },
            marginTop: { xs: 4, sm: 6, md: 8 },
          }}
        >
          {t("tools.title")}
        </Typography>

        <Box textAlign="center">
          <QuoteComponent
            quote={t("education.digitalBreakMessage")}
            author={t("Conciencia Digital")}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenModal}
            sx={{
              borderRadius: "12px",
              padding: "10px 20px",
              fontSize: "1rem",
              backgroundColor: "#10A5D1",
              "&:hover": { backgroundColor: "#0d8bb5" },
            }}
          >
            {t("tools.openPomodoro")}
          </Button>
        </Box>

        <Card
          sx={{
            borderRadius: "16px",
            boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.3s ease, box-shadow 0.3s ease",
            "&:hover": {
              transform: "translateY(-8px)",
              boxShadow: "0px 16px 32px rgba(0, 0, 0, 0.2)",
            },
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardContent sx={{ textAlign: "center", padding: "20px" }}>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
            >
              {t("tools.tutorialsTitle")}
            </Typography>
            <CardMedia
              component="img"
              height="250"
              image={PortadaTutorial}
              alt="Portada de tutorials"
            />
            <Typography variant="body2" color="textSecondary">
              {t("tools.tutorialDescription")}
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "center", paddingBottom: "16px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/tools/tutorials")}
              sx={{
                borderRadius: "25px",
                padding: "0.75rem 2.5rem",
                fontSize: "1rem",
                backgroundColor: "#10A5D1",
                "&:hover": { backgroundColor: "#0d8bb5" },
              }}
            >
              {t("tools.openTutorials")}
            </Button>
          </CardActions>
        </Card>
      </Container>
      <Footer />

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            px: 2,
          }}
        >
          <PomodoroTimer onClose={handleCloseModal} />
        </Box>
      </Modal>
    </div>
  );
};

export default ToolsScreen;
