import React, { useState } from "react";
import { Container, Typography, Box, Skeleton } from "@mui/material";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CustomButton from "../components/CustomButton";
import logo from "../assets/ConcienciaDigitalLogo1.png";
import QuestionsAccordeon from "../components/QuestionsAccordeon";
import { useLanguageStore } from "../components/utils/LanguageStore";

const HomeScreen: React.FC = () => {
  const { t } = useLanguageStore();
  const [loaded, setLoaded] = useState(false); // Estado para controlar si la imagen está cargada

  return (
    <div
      style={{
        backgroundColor: "#f0f0f0",
        margin: 0,
        padding: 0,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header />
      <Container
        maxWidth="md"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          margin: "0 auto",
          backgroundColor: "transparent",
          display: "flex",
          flexDirection: "column",
          alignItems: "center", 
          justifyContent: "center", 
          minHeight: "calc(100vh - 64px)",
        }}
      >
        <Box
          textAlign="center" 
          py={5}
          sx={{
            width: "100%", 
            display: "flex",
            flexDirection: "column",
            alignItems: "center", 
            justifyContent: "center", 
          }}
        >
          {!loaded && (
            <Skeleton
              variant="rectangular"
              width={300}
              height={300}
              sx={{
                borderRadius: "8px",
                marginBottom: "15px",
              }}
            />
          )}

          {/* Imagen del logo */}
          <img
            src={logo}
            loading="eager"
            alt="Conciencia Digital Logo"
            onLoad={() => setLoaded(true)} // Marca la imagen como cargada
            style={{
              height: "300px",
              width: "auto",
              marginBottom: "15px",
              objectFit: "contain",
              display: loaded ? "block" : "none", // Muestra la imagen solo cuando está cargada
            }}
          />

          {/* Texto debajo del logo */}
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              mt: 1,
              mb: 3,
              textAlign: "center", // Asegura que el texto también esté centrado
            }}
          >
            {t("home.welcomeMessage")}
          </Typography>

          {/* Botón */}
          <CustomButton
            label={t("home.welcomeButton")}
            to="/articulos"
            onClick={() => console.log("Button clicked!")}
          />
        </Box>

        <QuestionsAccordeon />

        <Box sx={{ height: "100px", backgroundColor: "#f0f0f0" }} />
        <Box
          sx={{
            paddingBottom: 5,
            marginTop: -10,
            textAlign: "center",
            backgroundColor: "#f0f0f0",
          }}
        >
          <Typography
            id="nosotros"
            variant="h2"
            component="h2"
            gutterBottom
            sx={{
              color: "#10A5D1",
              textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            }}
          >
            {t("home.aboutUsTitle")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("home.aboutUsMessage")}
          </Typography>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {t("home.initiatives")}
            <br />
            <strong>{t("home.hashtags")}</strong>
          </Typography>

          <CustomButton
            label={t("home.supportButton")}
            to="/contacto"
            onClick={() => console.log("Button clicked!")}
            sx={{ mt: 4 }}
          />
        </Box>

        <Box sx={{ height: "300px", backgroundColor: "#f0f0f0" }} />
      </Container>

      <Footer />
    </div>
  );
};

export default HomeScreen;
