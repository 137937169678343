import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import logo from "../assets/ConcienciaDigitalLogo1.png";
import LanguageSwitcher from "./LanguageSwitcher";
import { useLanguageStore } from "../components/utils/LanguageStore";

const Header: React.FC = () => {
  const { t } = useLanguageStore();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [visible, setVisible] = useState(true); // Controla la visibilidad del header

  const pages: { name: string; path: string }[] = [
    { name: t("header.home"), path: "/" },
    { name: t("header.education"), path: "/educacion" },
    { name: t("header.articles"), path: "/articulos" },
    { name: t("header.tools"), path: "/herramientas" },
    { name: t("header.contact"), path: "/contacto" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setVisible(scrollTop < lastScrollTop || scrollTop < 100); 
      setLastScrollTop(scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "#1871b1",
          boxShadow: "none",
          top: visible ? "0" : "-80px",
          transition: "top 0.3s ease-in-out",
          zIndex: 1100,
        }}
      >
        <Toolbar sx={{ px: 2, minHeight: 64, display: "flex", justifyContent: "space-between" }}>
          {/* Logo a la izquierda */}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link to="/">
              {!logoLoaded && (
                <Skeleton variant="rectangular" width={80} height={80} sx={{ borderRadius: "50%" }} />
              )}
              <img
                src={logo}
                loading="eager"
                alt="Conciencia Digital Logo"
                onLoad={() => setLogoLoaded(true)}
                style={{
                  height: 80,
                  width: "auto",
                  display: logoLoaded ? "block" : "none",
                }}
              />
            </Link>
          </Box>

          {/* Menú en Desktop alineado a la derecha */}
          <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 3 }}>
            {pages.map((page) => (
              <Link key={page.name} to={page.path} style={{ textDecoration: "none" }}>
                <Button
                  sx={{
                    color: "white",
                    transition: "color 0.3s ease",
                    "&:hover": { color: "#d1e1f0", textDecoration: "underline" },
                  }}
                >
                  {page.name}
                </Button>
              </Link>
            ))}
          </Box>

          {/* Menú responsivo en Mobile */}
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="menu"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              sx={{ color: "white" }}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  <Link to={page.path} style={{ textDecoration: "none", color: "black" }}>
                    {page.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          {/* Iconos sociales + LanguageSwitcher */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <LanguageSwitcher />
            <Tooltip title={t("header.socialMediaTooltip")}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <a href="https://www.linkedin.com/company/concienciadigital/" target="_blank" rel="noopener noreferrer">
                  <IconButton
                    sx={{ p: 0, color: "#f0f0f0", transition: "color 0.3s ease", "&:hover": { color: "#10A5D1" } }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                </a>
                <a href="https://www.youtube.com/watch?v=GSn2oU3lHhk" target="_blank" rel="noopener noreferrer">
                  <IconButton
                    sx={{ p: 0, color: "#f0f0f0", transition: "color 0.3s ease", "&:hover": { color: "#10A5D1" } }}
                  >
                    <PodcastsIcon />
                  </IconButton>
                </a>
              </Box>
            </Tooltip>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" sx={{ minHeight: 64, bgcolor: "transparent" }} />
    </>
  );
};

export default Header;
